import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

export const CookiesPolicy = () => {
  return(
    <Wrapper>
        Pending.
    </Wrapper>
  )
}