export const createdGameMessages = {
  0: {
    id: 0,
    message: 'En serio: ¿has pensado en la petanca?'
  },
  1: {
    id: 1,
    message: 'Hablemos de esto que has hecho'
  },
  2: {
    id: 2,
    message: 'También puedes ir a caminar por la montaña'
  },
  3: {
    id: 3,
    message: 'No se lo diremos a nadie...'
  },
  4: {
    id: 4,
    message: 'Tiger Woods, año 2009'
  },
  5: {
    id: 5,
    message: 'Tito... muy mal...'
  },
  6: {
    id: 6,
    message: 'Hay profesores que te pueden ayudar'
  },
  7: {
    id: 7,
    message: '¿Conoces una cosa llamada driving range?'
  },
  8: {
    id: 8,
    message: 'Enhorabuena si no has roto ningún palo'
  },
  9: {
    id: 9,
    message: 'Jornada de reflexion'
  },
  10: {
    id: 10,
    message: 'no, no, no y no'
  },
  11: {
    id: 11,
    message: 'Disaster'
  },
  12: {
    id: 12,
    message: 'Pan con aceite y sal, pero sin tomate'
  },
  13: {
    id: 13,
    message: 'Ni fu, ni fa, ni fe, ni fi...'
  },
  14: {
    id: 14,
    message: 'Esas noches que te emborrachas pero no ligas, pero te lo pasas decente'
  },
  15: {
    id: 15,
    message: 'Ni fu, ni fa, ni fe, ni fi...'
  },
  16: {
    id: 16,
    message: 'Sales y ligas. No es la que querias pero ligas'
  },
  17: {
    id: 17,
    message: 'Callando bocas!'
  },
  18: {
    id: 18,
    message: 'Así tendría que ser cada día!!'
  },
  19: {
    id: 19,
    message: 'Como idea: Bajaté el handicap!'
  },
  20: {
    id: 20,
    message: 'En serio: Bajaté el handicap!'
  },
  21: {
    id: 21,
    message: 'Muy loco esto que has hecho, ¿no?'
  },
  22: {
    id: 22,
    message: 'JO-DER, no hay palabras... bueno sí: PUTO AMO'
  },
  23: {
    id: 23,
    message: 'Hoy ha entrado TODO!'
  },
  24: {
    id: 24,
    message: '¿Qué acaba de pasar aquí?'
  },
  25: {
    id: 25,
    message: '¡¡AAiiii la virgen!! ¡Se nos ha aparecido!'
  },
  222: {
    id: 222,
    message: 'Esto lo vas a recordar toda la vida! Enhorabuena por tu hoyo en uno!!!!!!!!!!!!'
  },
};